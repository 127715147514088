import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Spring 2022 Registration Coming Soon`}</h1>
    <p>{`The Spring 2022 Lovelace Learning Labs registration will open `}<em parentName="p">{`February 25th 2022 at noon PST`}</em>{`.`}</p>
    <p>{`Registrants should be Ada Alumni.`}</p>
    <h2>{`Visit our Workshops Page`}</h2>
    <p>{`Take a look at our `}<a parentName="p" {...{
        "href": "https://lll.adadev.org/upcoming_workshops"
      }}>{`Spring workshops page`}</a>{` to learn more.`}</p>
    <p>{`We will have workshops on:`}</p>
    <ul>
      <li parentName="ul">{`Technical Topics`}</li>
      <li parentName="ul">{`Self-Care`}</li>
      <li parentName="ul">{`Financial Advice`}</li>
      <li parentName="ul">{`Personal & Career Growth`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      